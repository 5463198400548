import React, { useEffect, useState } from "react"
import axios from "axios"
const ContactForm = () => {
  const [SuccessMessage, setSuccessMessage] = useState("hide")
  const [ErrorMessage, setErrorMessage] = useState("")
  const [Name, setName] = useState("")
  const [Email, setEmail] = useState("")
  const [Phone, setPhone] = useState("")
  const [City, setCity] = useState("Bangalore")
  const [Message, setMessage] = useState("")
  const [Button, SetButton] = useState(false)
  const [NameError, setNameError] = useState("")
  const [EmailError, setEmailError] = useState("")
  const [PhoneError, setPhoneError] = useState("")
  const [CityError, setCityError] = useState("Bangalore")
  const [MessageError, setMessageError] = useState("")
  function validateEmail(email) {
    // Regular expression to check the email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    // Check if the email format is valid
    if (!emailRegex.test(email)) {
      return false
    }

    return true
  }
  const handleSubmit = e => {
    e.preventDefault()

    if (Name !== "" && Email !== "" && Phone !== "") {
      setErrorMessage("")
      if (Name.length <= 2) {
        setNameError("Enter Vaild Name")
      } else {
        setNameError("")
      }
      if (!validateEmail(Email)) {
        setEmailError("Please Enter Vaild Email Address")
      } else {
        setEmailError("")
      }
      if (!Phone.match("[0-9]{10}")) {
        setPhoneError("Please Enter Vaild Mobile No")
      } else {
        setPhoneError("")
      }
      if (!Message.length <= 5) {
        setMessageError("Please Enter Vaild Message")
      } else {
        setMessageError("")
      }
      if (
        Name.length >= 2 &&
        validateEmail(Email) &&
        Phone.match("[0-9]{10}") &&
        Message.length >= 5
      ) {
        axios
          .post(`https://gofloaters.firebaseapp.com/website/contactform`, {
            name: Name,
            emailId: Email,
            phone: "+91" + Phone,
            city: City,
            message: Message,
          })
          .then(res => {
            setSuccessMessage("show")
            setName("")
            setEmail("")
            setPhone("")
            setMessage("")
            setCity("")
            setErrorMessage("")
            SetButton(false)
          })
      }
    } else {
      setNameError("Enter Vaild Name")
      setEmailError("Please enter vaild email address")
      setPhoneError("Please enter vaild mobile no")
      setMessageError("Please enter vaild message")
    }
  }
  // useEffect(() => {
  //   if (
  //     Name.length > 2 &&
  //     Phone.match("[0-9]") &&
  //     Phone.length >= 10 &&
  //     validateEmail(Email)
  //   ) {
  //     SetButton(false)
  //   } else {
  //     SetButton(true)
  //   }
  // })
  return (
    <>
      {SuccessMessage === "hide" ? (
        <div className="FormMobile" style={{ overflowX: "auto" }}>
          <p style={{ color: "#454545" }}>
            Complete the form to get a call back from GoFloaters team member to
            help you.
          </p>

          <form>
            <div className="form-group">
              <label htmlFor="Name">
                Your Name *:{" "}
                <span style={{ color: "red", fontSize: "12px" }}>
                  {NameError}
                </span>
              </label>
              <input
                type="text"
                name="name"
                className="form-control"
                value={Name}
                onChange={e => {
                  setName(e.currentTarget.value)
                  setErrorMessage("")
                }}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">
                Email Address *:{" "}
                <span style={{ color: "red", fontSize: "12px" }}>
                  {EmailError}
                </span>
              </label>
              <input
                type="email"
                name="email"
                className="form-control"
                value={Email}
                onChange={e => {
                  setEmail(e.currentTarget.value)
                  setErrorMessage("")
                }}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="Phone">
                Phone *:{" "}
                <span style={{ color: "red", fontSize: "12px" }}>
                  {PhoneError}
                </span>
              </label>
              <input
                type="text"
                name="phone"
                className="form-control"
                value={Phone}
                onChange={e => {
                  var phone = e.currentTarget.value
                  if (!phone.match("[0-9]{10}")) {
                    setPhone(e.currentTarget.value)
                  } else {
                    setErrorMessage("")
                    setPhone(e.currentTarget.value)
                  }
                }}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="City">City *:</label>
              <select
                type="text"
                name="phone"
                className="form-control"
                value={City}
                onChange={e => {
                  // console.log(e.currentTarget.value)
                  setCity(e.currentTarget.value)
                  setErrorMessage("")
                }}
                required
              >
                <option value="Agartala">Agartala</option>
                <option value="Ahmedabad">Ahmedabad</option>
                <option value="Bangalore">Bangalore</option>
                <option value="Bhopal">Bhopal</option>
                <option value="Bhubaneshwar">Bhubaneshwar</option>
                <option value="Chandigarh">Chandigarh</option>
                <option value="Chennai">Chennai</option>
                <option value="Coimbatore">Coimbatore</option>
                <option value="Dehradun">Dehradun</option>
                <option value="Faridabad">Faridabad</option>
                <option value="Ghaziabad">Ghaziabad</option>
                <option value="Goa">Goa</option>
                <option value="Gurugram">Gurugram</option>
                <option value="Hyderabad">Hyderabad</option>
                <option value="Imphal">Imphal</option>
                <option value="Indore">Indore</option>
                <option value="Jalandhar">Jalandhar</option>
                <option value="Kochi">Kochi</option>
                <option value="Kolkata">Kolkata</option>
                <option value="Lucknow">Lucknow</option>
                <option value="Madurai">Madurai</option>
                <option value="Mangaluru">Mangaluru</option>
                <option value="Mumbai">Mumbai</option>
                <option value="Mysore">Mysore</option>
                <option value="Nagpur">Nagpur</option>
                <option value="New Delhi">New Delhi</option>
                <option value="Noida">Noida</option>
                <option value="Puducherry">Puducherry</option>
                <option value="Pune">Pune</option>
                <option value="Rajkot">Rajkot</option>
                <option value="Ranchi">Ranchi</option>
                <option value="Siliguri">Siliguri</option>
                <option value="Thiruvananthapuram">Thiruvananthapuram</option>
                <option value="Trichy">Trichy</option>
                <option value="Vadodara">Vadodara</option>
                <option value="Vijayawada">Vijayawada</option>
                <option value="Visakhapatnam">Visakhapatnam</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="YourMessage">
                Your Requirement:{" "}
                <span style={{ color: "red", fontSize: "12px" }}>
                  {MessageError}
                </span>
              </label>
              <textarea
                style={{ minHeight: "50px" }}
                type="text"
                name="Tell Us About Your Requirement"
                className="form-control"
                value={Message}
                onChange={e => {
                  setMessage(e.currentTarget.value)
                  setErrorMessage("")
                }}
                placeholder="Tell Us About Your Requirement"
              ></textarea>
            </div>
            <button
              onClick={handleSubmit}
              disabled={Button}
              className="btn btn-default"
            >
              Submit
            </button>
          </form>

          {ErrorMessage === "" ? (
            ""
          ) : (
            <p style={{ color: "red", fontWeight: "bold" }}>
              <br />

              {ErrorMessage}
            </p>
          )}
        </div>
      ) : (
        <div>
          <p style={{ color: "red", fontWeight: "bold" }}>{ErrorMessage}</p>
          <div className={"message " + SuccessMessage}>
            Thank you for contacting us – we will get back to you soon!
          </div>
        </div>
      )}
    </>
  )
}

export default ContactForm
